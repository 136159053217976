.categories {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    TABLE.legend-list {
        border-collapse: collapse;
        TR {
            cursor: pointer;
            &:hover {
                background-color: var(--accent);
                color: var(--text-accented);
            }            
        }
    }
    .category-name {
        display: flex;
        align-items: center;
        padding: 5px;
    }
    
    .legend-color {
        height: 12px;
        width: 12px;
        margin: 5px;
    }
}
