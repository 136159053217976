.prisoners {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .prisoner {
        height: 20px;
        width: auto;
    }
}
