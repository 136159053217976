.budget-list {
    TABLE {
        TR {
            cursor: pointer;
        }
        TD {
            &:hover {
                background-color: var(--hover);
            }
        }
    }
}