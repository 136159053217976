.calendar {
    height: 100%;
    .header {
        font-size: 18px;
        padding: 5px;
        margin: 5px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .control-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    table {
        border: 1px solid var(--bs-gray-400);
        border-collapse: collapse;
        width: 100%;
        height: 100%;
        opacity: .9;
        td, th {
            border: 1px solid var(--bs-gray-400);
            background-color: var(--background);
            padding: 5px;
            width: 14.2857%;
            font-size: 14px;
        }
        td:hover {
            background-color: rgba(var(--bs-primary-rgb), .5);
            cursor: crosshair;
        }
        .day {
            height: 80px;
            vertical-align: text-top;
        }
        .today {
            background-color: rgba(var(--bs-info-rgb), .5);
            cursor: crosshair;
            &:hover {
                background-color: rgba(var(--bs-info-rgb), .5);
            }
        }
    }
}