
/* Large Screens */
@media only screen and (min-width: 501px) {
    .youtube-embed {
        width: 560px;
        height: 315px;
    }     
}

/* Small Screens */
@media only screen and (max-width: 500px) {
    .youtube-embed {
        width: 100%;
        height: 200px;
    }    
}
