.move {
    margin: 2px 0;
    cursor: pointer;
    &:hover {
        color: var(--accent);
    }
    &.active {
        color: var(--accent);
    }
}

/* Large Screens */
@media only screen and (min-width: 701px) {
    .chess-menu {
        textarea {
            height: 80px;
            resize: none;
        }
        .move-container {
            height: 100px;
            overflow-y: auto;
            width: 225px;
        }
    }
}

/* Small Screens */
@media only screen and (max-width: 700px) {

}
