.list {
    .list-ul {
        list-style-type: none;
        padding: 0;
        margin: 0 0 10px 10px;
    }
    
    .list-ul li {
        margin: 0;
        line-height: 1.5;
    }
}

/* Large Screens */
@media only screen and (min-width: 701px) {
    .list {
        max-height: 90vh;
        width: calc(100vw - 350px);
        .list-items {
            width: 100%;
            max-height: calc(100vh - 210px);
            overflow: auto;
        }
    }
}

/* Small Screens */
@media only screen and (max-width: 700px) {

}
