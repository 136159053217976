.file-upload-data {
    .file-table {
        border-collapse: collapse;
        border: 1px solid var(--bs-gray-400);
        tr {
            cursor: default;
            &:hover {
                background-color: transparent;
            }
            th {
                text-align: right;
                vertical-align: top;
                padding: 5px;
            }
            td {
                text-align: left;
                vertical-align: top;
                padding: 5px;
                .thumbnail {
                    max-width: 150px;
                    max-height: 150px;
                }
            }
        }
    }
}