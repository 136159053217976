.tag {
    background-color: var(--bs-primary);
    font-size: 12px;
    color: var(--white);
    height: 16px;
    border-radius: 8px;
    padding: 5px;
    margin: 3px;
    display: flex;
    align-items: center;
    cursor: default;
    &.clickable {
        &:hover {
            cursor: pointer;
            background-color: var(--accent);
            color: var(--text-accented);
        }
    }
}