.high-scores {
    h2 {
        text-align: center;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    TABLE {
        margin: 5px;
        border-collapse: collapse;
        TH {
            padding: 5px;
        }
        TD {
            padding: 5px;
            text-align: center;
        }
    }
}
