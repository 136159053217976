.selectable-piece-list {
    height: 300px;
    max-height: 50vh;
    overflow-y: scroll;
    .selectable-piece {
        cursor: pointer;
        &.selected {
            background-color: gray;
            color: white;
        }
    }
}
