.music-entry {
    .watch {
        font-size: 14px;
        .clickable {
            color: var(--link);
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            padding-bottom: 3px;
            &:hover {
                color: var(--accent);
            }
        }
    }
    .music-description {
        font-size: 14px;
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #CCC;        
    }
}
