.editable-dashboard-element {
    border: 1px solid var(--bs-gray-400);
    border-radius: 10px;
    margin: 5px 0;
    padding: 5px;
    .controls {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    select {
        margin: 5px;
        width: calc(100% - 10px);
    }
}
