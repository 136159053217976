.tracker-group-list {
    TEXTAREA {
        width: 199px;
    }
    .groups {
        display: flex;
        flex-wrap: wrap;
        max-width: 600px;
    }
   .button-content {
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
            color: var(--bs-primary);
            font-size: 1em;
        }
    }
    .friend-name {
        font-size: 14px;
    }
}
