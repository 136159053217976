/* Large Screens */
@media only screen and (min-width: 501px) {
    .yearly-notes {
        .notes {
            display: flex;
            flex-wrap: wrap;
            .note {
                margin: 5px;
                padding: 5px;
                border: 1px solid var(--bs-gray-400);
                width: 400px;
                cursor: pointer;
                &:hover {
                    background-color: var(--accent);
                    color: var(--text-accented);
                }
            }
        }
    }
}

/* Small Screens */
@media only screen and (max-width: 500px) {
    .yearly-notes {
        .notes {
            display: flex;
            flex-wrap: wrap;
            .note {
                margin: 5px;
                padding: 5px;
                border: 1px solid var(--bs-gray-400);
                width: 100%;
                cursor: pointer;
                &:hover {
                    background-color: var(--accent);
                    color: var(--text-accented);
                }
            }
        }
    }
}