.tracker-line-graph {
    //max-width: 1000px;
    .custom-tooltip {
        background-color: var(--background);
        opacity: .8;
        border: 1px solid var(--bs-gray-400);
        padding: 10px;
        TABLE {
            TR {
                TD, TH {
                    padding: 5px;
                    text-align: left;
                }
            }
        }
    }
}