.tooltip {
    display: inline-block;
    align-items: center;
    position: relative;
    .icon {
        color: var(--bs-secondary);
        font-size: 20px;
        margin: 5px;
    }
    .text {
        color: var(--menu-text);
        font-size: 14px;
        background-color: var(--menu-background);
        padding: 5px;
        width: 150px;
        border-radius: 10px;
        position: absolute;
        z-index: 99;
        box-shadow: 2px 2px 10px var(--shadow);
        transition: all 300ms;
        line-height: 1.3em;
        &.hide {
            visibility: hidden;
            opacity: 0;
        }
    }
}
