.loading-container {
    width: 100%;
    text-align: center;
}

.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
    padding: 10px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid gray;
    border-color: gray transparent gray transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}