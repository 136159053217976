.reference-value-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .value-input {
        margin-right: 2px;
    }
    .time-input {
        margin-right: 2px;
    }
    .color-input {
        margin-right: 2px;
    }
    .label-input {
        margin-right: 2px;
    }
}