.tracker-data-table {
    TABLE {
        border-collapse: collapse;
        TR {
            cursor: pointer;
            &:hover {
                background-color: var(--accent);
                color: var(--text-accented);
            }
            TD, TH {
                padding: 5px;
            }
        }
    }
}
