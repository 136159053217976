$main-flower: #517bd6;
$stem: #51d691;
$pot: rgb(250, 170, 24); //#FF7043;
$pot-shadow: rgb(213, 139, 4); //#d66f51;
$transform-leaves-left: rotate(-283deg) skewX(-12deg)  skewY(-4deg);
$transform-leaves-right: rotate(-163deg) skewX(-12deg) skewY(-4deg);

.flower-container {
  display: flex;
  justify-content: center;
  position: absolute;
}

.flower {
  position: relative;
  cursor: pointer;
  &:hover {
    border: 5px solid transparent;
  }
  &__head {
    width: 120px;
    height: 40px;
    border-radius: 20px;
    //background: $main-flower;
    margin-top: 50px;

    &::after, &::before {
      content: '';
      position: absolute;
      background: inherit; // $main-flower;
      border-radius: 20px;
      width: 120px;
      height: 40px;
      transform: rotate(60deg);
    }

    &::before {
      transform: rotate(-60deg);
    }
  }

  &__eye {
    position: absolute;
    width: 20px;
    height: 20px;
    z-index: 1;
    background: #fff;
    border-radius: 50%;
    animation: blink 3s infinite;

    &.left {
      left: 30%;
    }

    &.right {
      left: 55%;
    }

    span {
      position: absolute;
      transition: all 0.3s;
      width: 10px;
      height: 10px;
      margin-left: 5px;
      margin-top: 5px;
      background: #000;
      border-radius: 50%;
    }
  }
  
  &__nose {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 80%;
    width: 15px;
    height: 15px;
    z-index: 1;
    background: pink;
    border-radius: 50%;
  }

  &__leaf {
    width: 30px;
    height: 30px;
    background: $stem;
    position: absolute;
    top: 130px;
    left: 21px;
	  border-radius: 50px;
	  border-top-right-radius: 0;
    transform: $transform-leaves-left;

    &--shadow {
      position: absolute;
      background: rgba(0, 0, 0, 0.25);
      height: 15px;
      width: 15px;
      top: 144px;
      left: 29px;
	    border-radius: 50px;
	    border-top-right-radius: 0;
      transform: $transform-leaves-left;

      &.oposite {
        top: 164px;
        left: 75px;
        transform: $transform-leaves-right;
      }
    }

    &.oposite {
      top: 150px;
      left: 70px;
      transform: $transform-leaves-right;
    }
  }

  &__stem {
    position: absolute;
    height: 150px;
    width: 8px;
    background: $stem;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  &__pot {
    width: 80px;
		height: 20px;
		background-color: $pot;
    position: absolute;
    top: 190px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-weight: bold;

    &--shadow {
      width: 72px;
			position: absolute;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 10px solid $pot-shadow;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    &--bottom {
      width: 65px;
      height: 0px;
      position: absolute;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 50px solid $pot ;
      top: 20px;
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@keyframes blink {
 0%, 100% {
        transform: scale(1, .05);
    }
    5%,
    95% {
        transform: scale(1, 1);
    }
}
