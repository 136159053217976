.tracker-group-dialog {
    TABLE {
        TR {
            TH {
                padding: 5px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                &.top {
                    vertical-align: top;
                }
            }
            TD {
                padding: 5px;
                input[type="checkbox"] {
                    margin: 5px;
                }
            }
        }
    }
}

/* Large Screens */
@media only screen and (min-width: 501px) {
    .tracker-group-dialog {
        width: calc(100vw - 350px);
    }
}