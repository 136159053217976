.data-table {
    TABLE {
        // border-collapse: collapse;
        border-top: 0;
        margin-bottom: 10px;
        // width: 100%;
        //opacity: .9;
        THEAD {
            TR {
                // border-bottom: 1px solid var(--bs-gray-400);
                // background-color: var(--background);
                TH {
                    // padding: 10px;
                    &.sortable {
                        &:hover {
                            background-color: var(--hover);
                        }
                    }
                    .sort-icon {
                        font-size: 16px;
                        color: gray;
                    }
                }
            }
            TR.header-row {
                // border: 1px solid var(--bs-gray-400);
            }
            TR.filter-row {
                // background-color: var(--hover);
                // border: 1px solid var(--bs-gray-400);
                TD {
                    // text-align: center;
                    // input, select {
                    //     margin: 5px;
                    //     padding: 3px;
                    //     background-color: var(--background);
                    //     height: 100%;
                    //     border: 0;
                    //     font-size: 14px;
                    // }
                }
            }
            TR.pagination-row {
                // background-color: transparent;
                border-top: 0;
                TD { 
                    border-top: 0;
                }
                .pagination {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 30px;
                        min-width: 30px;
                        background-color: transparent;
                        border: 2px solid transparent;
                        color: var(--bs-primary);
                        cursor: pointer;
                        &:hover {
                            border: 2px solid var(--bs-primary);
                        }
                    }
                    button[disabled] {
                        border: 2px solid transparent;
                        color: var(--text-muted);
                    }
                    // select {
                    //     padding: 2px;
                    // }
                    .page-control {
                        margin: 5px;
                    }
                    .goto-page-input {
                        margin-left: 5px;
                        width: 50px;
                    }
                }
            }
        }
        TBODY {
            // border: 1px solid var(--bs-gray-400);
            // TR {
            //     cursor: pointer;
            //     background-color: var(--background);
            //     &:hover {
            //         background-color: var(--accent);
            //         color: var(--text-accented);
            //     }
            //     TD {
            //         padding: 10px;
            //         font-size: 15px;
            //         text-align: center;
            //     }
            // }
            // TR:nth-child(even) {
            //     background-color: var(--hover);
            //     &:hover {
            //         background-color: var(--accent);
            //         color: var(--text-accented);
            //     }
            // }
        }
    }
}