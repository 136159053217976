.weekly {
    .date-header {
        color: var(--heading-text);
        display: flex;
        align-items: center;
        &.today {
            color: var(--bs-secondary);
        }
    }
    .events {
        list-style-type: none;
        li {
            color: var(--bs-body-color);
            line-height: 1.5;
        }
    }
    .no-events {
        list-style-type: none;
        li {
            color: var(--text-muted);
        }
    }
}