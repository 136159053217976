.entries {
    .category-name {
        cursor: pointer;
        color: var(--bs-primary);
        &:hover {
            color: var(--accent);
        }
    }
    .total {
        font-size: 24px;
        font-weight: bold;
        margin: 5px;
        .amount {
            color: var(--bs-secondary);         
        }
    }
    .savings-type {
        font-weight: bold;
        margin-top: 10px;
        &.spent {
            color: var(--bs-secondary);
        }
        &.saved {
            color: var(--success);
        }
    }
    TABLE.entries-list {
        border-collapse: collapse;
        TR {
            cursor: pointer;
            &:hover {
                background-color: var(--accent);
                color: var(--text-accented);
            }
            TD {
                padding: 5px;
            }
            &.not-final {
                color: var(--text-muted);
                &:hover {
                    color: var(--text-accented);
                }
            }
            &.final {
                color: var(--bs-body-color);
                &:hover {
                    color: var(--text-accented);
                }
            }
            &.total-row {
                border-top: 1px solid var(--bs-gray-400);
                cursor: default;
                font-weight: bold;
                &:hover {
                    background-color: transparent;
                }
            }
            .receipt-icon {
                color: var(--success);
                font-size: 14px;
            }
        }
    }
}