.calendar-event {
    color: white;
    background-color: #444;
    border-radius: 9px;
    height: 18px;
    font-size: 11px;
    padding: 2px 5px 2px 5px;
    margin: 2px;
    display: flex;
    justify-content: left;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        background-color: var(--accent);
        color: var(--text-accented);
    }
}

/* Large Screens */
@media only screen and (min-width: 951px) {
    .calendar-event {
        max-width: calc((100vw / 7) - 20px);
    }
}

/* Small Screens */
@media only screen and (max-width: 950px) {
    .calendar-event {
        max-width: calc(100vw / 7);
    }
}

