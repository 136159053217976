.start-page {
    background-color: black;
    height: 100vh;
    overflow: hidden;
    scroll-behavior: auto;
    background-color: #000000;
    color: #808080;

    A {
        color: #808080;
        text-decoration: none;
    }
        
    A:hover {
        color: #0050AA;
    }
        
    IMG {
        margin: 2px;
    }
        
    .icons {
        margin: auto;
    }
        
    .icons td {
        width: 75px;
        text-align: center;
    }
        
    .links {
        display: none;
        text-align: center;
    }

    .module-icon {
        font-size: 48px;
        color: white;
    }
        
    UL {
        list-style-type: none;
        padding: 0px;
    }
         
    LI {
        padding: 3px;
    }
}
