.monthly-summary {
    .summary-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .group-name {
            text-transform: uppercase;
            color: var(--heading-text);
            font-size: 14px;
        }
        .amount {
            font-size: 24px;
            margin-left: 20px;
        }
        .amount-group {
            padding: 5px;
            margin-bottom: 10px;
        }
        .clickable {
            cursor: pointer;
            &:hover {
                background-color: var(--accent);
                color: var(--text-accented);
            }
        }
        .income {
            color: var(--bs-body-color);
        }
        .expenses {
            color: var(--bs-primary);
        }
        .savings {
            color: var(--bs-info);
        }
    }
}
