.login {
    display: flex;
    .login-form {
        align-items: flex-start;
        input {
            margin: 5px;
            max-width: 200px;
        }
    }
}
