.playlists {
    .song {
        padding: 15px;
        border: 1px solid gray;
        cursor: pointer;
        &.active {
            color: yellow;
        }
    }
}
