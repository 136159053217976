.kitty-game {
    height: calc(100vh - 60px);
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
        font-size: 24px;
    }
    .message {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        color: var(--text-muted);
    }
    .score-dialog-container {
        position: absolute;
        left: 60px;
        top: 0;
        height: 100vh;
        width: calc(100vw - 60px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    TABLE.grid {
        border-collapse: collapse;
        &.faded {
            opacity: .2;
        }
        .table-header {
            display: flex;
            justify-content: space-between;
            .score {
                text-align: left;
                font-size: 24px;
                color: var(--bs-primary);
                font-weight: bold;
            }
            .info {
                text-align: center;
            }
            .lives {
                text-align: right;
                color: red;
                vertical-align: bottom;
            }
        }
        TBODY {
            border: 2px solid var(--bs-gray-400);
            TD {
                text-align: center;
                vertical-align: center;
                margin: 0;
                padding: 0;
                &.wall {
                    background-color: black;
                }
                IMG {
                    height: auto;
                    width: auto;
                    max-height: calc(100% - 5px);
                    max-width: calc(100% - 5px);
                    padding: 0;
                }
            }
        }

    }
    .loading-images {
        position: absolute;
        left: -2000px;
        top: -2000px;
        img {
            height: 1px;
            width: 1px;
        }
    }
}
