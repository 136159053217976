.recipe-dialog {
    .existing-file {
        display: flex;
        align-items: center;
    }
    .type {
        input {
            margin-left: 10px;
        }
    }
    .description {
        width: 100%;
        resize: none;
        color: var(--bs-body-color);
        border: 1px solid var(--bs-gray-400);
        font-size: 16px;
        display: block;
        margin: 0;
    }
    .tag-list {
        display: flex;
        align-items: center;
    }
}

/* Large Screens */
@media only screen and (min-width: 951px) {
    .recipe-dialog {
        width: 100%;
        max-width: calc(100vw - 350px);
        .description {
            height: calc(100vh - 400px);
            min-height: 100px;
        }
    }
}

/* Small Screens */
@media only screen and (max-width: 950px) {
    .recipe-dialog {
        width: calc(100vw - 20px);
        .description {
            min-height: 100px;
        }
    }
}
