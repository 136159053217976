.delete-confirmation {
    .message {
        padding: 5px;
    }
    .user-input {
        padding: 5px;
    }
    .buttons {
        display: flex;
        justify-content: flex-start;
    }
}
