.monthly-summaries {
    TABLE {
        margin: 5px;
        border-collapse: collapse;
        TBODY {
            TR {
                cursor: pointer;
                &:hover {
                    background-color: var(--accent);
                    color: var(--text-accented);
                }
                TD {
                    padding: 5px 50px 5px 50px;
                }
                &.total-row {
                    border-top: 1px solid var(--bs-gray-400);
                    cursor: default;
                    font-weight: bold;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
