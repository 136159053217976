.kitty-intro {
    height: calc(100vh - 60px);
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    IMG {
        height: 75px;
        width: auto;
        margin-right: 10px;
    }
    p {
        text-align: center;
    }
    .instructions {
        border: 2px solid var(--bs-primary);
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
    }
    TABLE.credits {
        color: var(--text-muted);
        margin: 5px;
        TD {
            width: 50%;
            white-space: nowrap;
            text-align: center;
            padding: 15px;
            .credit-category {
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }
}
