.list-item {
    display: flex;
    align-items: flex-start;
}

.list-item-form {
    input {
        margin: 5px;
    }
}

.description {
    color: var(--text-muted);
    font-size: 14px;
    margin: 0 0 0 28px;
}
