.friends-list {
    list-style-type: none;
    padding-left: 0;
}

.friends-list li {
    display: flex;
    align-items: center;
}

.current-friends {
    display: flex;
    flex-wrap: wrap;
    .friend-section {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        .remove-btn {
            text-align: right;
        }
    }
}