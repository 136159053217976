nav, .nav {
    background-color: var(--bs-gray-700);
}

nav .nav-link, .navbar-brand {
    color: lightgray;
    &:hover {
        color: white;
    }
}

.btn {
    border-radius: 20px;
}

.red {
    color: red;
}

.white {
    color: white;
}

// .navbar-toggler {
//     background-color: black;
// }

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

a {
    //color: var(--link) !important;
    text-decoration: none !important;
    // &:hover {
    //     color: var(--accent) !important;
    // }
}

// INPUT, TEXTAREA {
//     font-size: 16px;
//     font-family: Arial, sans-serif;
//     background-color: transparent;
//     color: var(--bs-body-color);
//     border: 1px solid var(--bs-gray-400);
//     padding: 5px;
// }

// SELECT {
//     font-family: Arial, sans-serif;
//     padding: 3px;
//     background-color: transparent;
//     color: var(--bs-body-color);
//     border: 1px solid var(--bs-gray-400);
//     font-size: 14px;
//     OPTION, OPTGROUP {
//         background-color: var(--background);
//     }
// }

// LABEL {
//     margin-right: 5px;
// }

h1 {
    font-size: 24px !important;
    padding-top: 5px;
    font-weight: 700;
}

h2 {
    font-size: 18px !important;
    padding-top: 5px;
    font-weight: 700;
}

h3 {
    font-size: 15px !important;
    padding-top: 5px;
    font-weight: 700;
}

// .warning {
//     color: var(--warning);
// }

// .success {
//     color: var(--success);
// }

// .cancel {
//     color: var(--cancel);
// }

// .primary {
//     color: var(--bs-primary);
// }

// .secondary {
//     color: var(--bs-secondary);
// }

// .muted {
//     color: var(--text-muted);
// }

.clickable {
    cursor: pointer;
}

.file-thumbnails {
    display: flex;
    flex-wrap: wrap;
}

/* Large Screens */
@media only screen and (min-width: 701px) {
    main {
        //padding: 10px;
        margin: 0;
    }

    .mobile-only {
        display: none;
    }

    .module-container {
        display: flex;
    }

    .main-container {
        padding: 10px;
        width: 100%;
        &.with-side-menu {
            max-width: calc(100vw - 350px);
        }
    }
}

/* Small Screens */
@media only screen and (max-width: 700px) {
    main {
        padding: 5px;
        &.hide {
            display: none;
        }
    }

    .mobile-hide {
        display: none;
    }

    .main-container {
        padding: 5px;
        width: calc(100vw - 20px);
    }
}
