.yearly-goals {
    min-width: 350px;
    TABLE {
        border-collapse: collapse;
        TR {
            TD,TH {
                padding: 5px;
                &.amount {
                    padding-left: 10px;
                }
            }
            &.goal-row {
                cursor: pointer;
                &:hover {
                    background-color: var(--accent);
                    color: var(--text-accented);
                }
            }
        }
    }
    .total {
        font-weight: bold;
        margin: 5px;
        .amount {
            color: var(--bs-secondary);
        }
    }
}
