
/* Large Screens */
@media only screen and (min-width: 501px) {
    .music-menu {
        width: 225px;
    } 
}

/* Small Screens */
@media only screen and (max-width: 500px) {
    .music-menu {
        width: 100%;
    }    
}
