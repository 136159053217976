.tracker-data {
    .data-notes {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        justify-content: flex-end;
        .notes {
            margin: 0 20px;
            TEXTAREA {
                border: 0;
                min-width: 300px;
                min-height: 200px;
                background: transparent;
            }
        }
    }
    .notes {
        TEXTAREA {
            border: 0;
            min-width: 300px;
            min-height: 200px;
            background: transparent;
        }
    }
}

/* Large Screens */
@media only screen and (min-width: 501px) {
    .tracker-data {
        max-width: calc(100vw - 350px);
    }
}


/* Small Screens */
@media only screen and (max-width: 700px) {
    .tracker-data {
        max-width: calc(100vw - 20px);
    }
}
