.sharing-table {
    td, th {
        padding: 10px;
        div {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
}