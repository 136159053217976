.thumbnail {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 5px;
    cursor: pointer;
    opacity: .9;
    transition: all ease 300ms;
    display: flex;
    justify-content: center;
    align-items: middle;
    border-radius: 10px;
    &:hover {
        opacity: 1;
    }
    &.placeholder {
        background-color: #CCC;
        cursor: default;
    }
}
