.chessboard {
    border-collapse: collapse;
    TD.square {
        width: 9.5vmin;
        height: 9.5vmin;
        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px;
            img {
                width: 100%;
                height: 100%;
                cursor: grab;
            }
        }
    }
    TD.coord-file {
        padding-bottom: 5px;
        text-align: center;
        vertical-align: top;
    }
    TD.coord-rank {
        vertical-align: center;
        text-align: left;
        padding-right: 5px;
    }
    TD.coord-spacer {
        padding: 8px;
    }
}
