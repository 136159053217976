.multiple-file-upload-data {
    display: flex;
    flex-wrap: wrap;
    .close-btn {
        color: red;
        cursor: pointer;
        margin-right: 5px;
    }
    .thumbnail-container {
        text-align: center;
        margin: 5px;
    }
}
