.savings-tracker {
    display: flex;
    flex-direction: column;
    .savings-tracker-container {
        overflow-x: auto;
    }
    .savings-tracker-table {
        border-collapse: collapse;
        THEAD {
            TR {
                TH {
                    text-align: center;
                }
            }
        }
        TBODY {
            TR {
                &:hover {
                    background-color: var(--bs-gray-400);
                }
                TD {
                    padding: 5px;
                }
                &.account {
                    border-bottom: 1px solid var(--bs-gray-400);
                    font-weight: bold;
                    TD {
                        padding-top: 7px;
                    }
                }
                &.spent {
                    color: var(--bs-secondary)
                }
                &.saved {
                    color: var(--success);
                }
            }
            .edit-btn {
                color: var(--heading-text);
                font-size: 16px;
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }
}

/* Large Screens */
@media only screen and (min-width: 501px) {
    .savings-tracker {
        max-width: calc(100vw - 350px);
        .savings-tracker-table {
            width: 100%;
        }
    }
}

/* Small Screens */
@media only screen and (max-width: 500px) {

}