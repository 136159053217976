.file-link {
    color: black;
    cursor: pointer;
    margin: 5px;
    padding: 5px 10px;
    height: 100px;
    width: 100px;
    border: 1px solid gray;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    &:hover {
        background-color: #FFC;
    }
    &.placeholder {
        cursor: default;
        &:hover {
            background-color: transparent;
        }
    }
}

.thumbnail-container {
    text-align: center;
    position: relative;
    &.removed {
        opacity: .5;
    }
    .dead {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 100px;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: red;
        cursor: pointer;
        background-color: rgba(0,0,0,.4);
        margin: 5px;
        border-radius: 10px;
        z-index: 1;
    }
    .close-btn {
        color: red;
        cursor: pointer;
    }
}
