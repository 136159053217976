/* Large Screens */
@media only screen and (min-width: 701px) {
    .side-menu {
        margin-right: 10px;
        border-right: 1px solid rgba(0,0,0,.03);
        .header {
            font-weight: bold;
            background-color: rgba(0,0,0,.03);
            padding: 15px;
            text-align: center;   
        }
        .body {
            padding: 15px;
            height: 100%;
        }
    }
}

/* Small Screens */
@media only screen and (max-width: 700px) {
    .side-menu {
        width: 100%;
        .header {
            font-weight: bold;
            background-color: rgba(0,0,0,.03);
            padding: 5px 15px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        .body {
            padding: 15px;
            border-bottom: 1px solid rgba(0,0,0,.03);
            display: block;
            opacity: 1;
            transition: all 500ms;
            height: auto;
            max-height: 100vh;
            &.is-collapsed {
                max-height: 0;
                opacity: 0;
                overflow: hidden;
                height: 0;
            }
        }
    }
}
