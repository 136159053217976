.tracker-data-view-toggle {
    display: flex;
    width: 220px;
    background: var(--section);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .option {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 5px;
        .view {
            margin-left: 5px;
        }
    }
    .active {
        background: var(--accent);
        color: var(--text-accented);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}
