.budget-dashboard {
    display: flex;
    flex-wrap: wrap;
}

/* Large Screens */
@media only screen and (min-width: 501px) {
    .budget-dashboard {
        max-width: calc(100vw - 350px);
    }
}