.file-viewer {
    background-color: rgba(0,0,0,.9);
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: calc(100vh);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .file-viewer-header {
        height: 50px;
        background-color: rgba(0,0,0,.6);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .file-name {
            color: white;
            margin-left: 10px;
            width: calc((100vw / 2) - 120px);
        }
        .controls {
            padding: 5px;
            display: flex;
            justify-content: center;
            width: 120px;
            .control-btn {
                font-size: 36px;
                color: blue;
                cursor: pointer;
                &:hover {
                    color: #FFC;
                }
            }
        }
        .close-btn {
            width: calc((100vw / 2) - 120px);
            display: flex;
            justify-content: flex-end;
            .close-btn-icon {
                z-index: 99;
                margin-right: 10px;
                font-size: 36px;
                color: white;
                cursor: pointer;
                &:hover {
                    color: #FFC;
                }
            }
        }
    }
    .main {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: calc(100vh - 50px);
        .rendered-image {
            width: calc(100vw - 20px);
            height: calc(100vh - 70px);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            margin-bottom: 10px;
        }
        .embedded-file {
            width: calc(100vw - 20px);
            height: calc(100vh - 70px);
            border: 0;
        }
    }
}