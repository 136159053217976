.list-items-due {
    max-height: 90vh;
    overflow-y: auto;
    .list-ul {
        list-style-type: none;
        padding: 0;
        margin: 0 0 10px 10px;
    }
    
    .list-ul li {
        margin: 0;
        line-height: 1.5;
    }
}
