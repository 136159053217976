.toggle {
    width: 30px;
    height: 16px;
    border: 0;
    box-shadow: 0 0 4px 1px var(--shadow);
    border-radius: 8px;
    display: flex;
    align-items: center;
    background-color: var(--background);
    cursor: pointer;
    margin: 10px;
    &.active {
        justify-content: flex-end;
    }
    &.inactive {
        justify-content: flex-start;
    }
    .selector {
        height: 14px;
        width: 14px;
        border-radius: 7px;
        background-color: var(--bs-primary);
        border: 0;
        margin: 1px;
        color: white;
        font-weight: bold;
        display: flex;
        font-size: 10px;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 1px 1px var(--bs-primary);
    }
}