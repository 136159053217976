.trust-fund {
    .family {
        border-radius: 20px;
    }
}

/* Large Screens */
@media only screen and (min-width: 701px) {
    .trust-fund {
        .family {
            max-width: 100%;
            max-height: 60vh;
            height: auto;
            width: auto;
        }
    }
}

/* Small Screens */
@media only screen and (max-width: 700px) {
    .trust-fund {
        .family {
            max-width: 100%;
            max-height: 30vh;
            width: auto;
            height: auto;
        }
    }
}
