.audio-player {
    text-align: center;
    margin: 10px;
    .title {
        font-size: 18px;
    }
    .play-pause-btn {
        font-size: 48px;
        cursor: pointer;
        margin: 10px;
    }
    .arrow-btn {
        font-size: 24px;
        cursor: pointer;
    }
}
