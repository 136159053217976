.chord {
    width: 60px;
    height: 60px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 8px;
    cursor: pointer;
    font-family: serif;
}
