.item-dialog {
    display: flex;
    form {
        display: flex;
        flex-direction: column;
        input {
            margin: 5px 0;
        }
    }
}

/* Large Screens */
@media only screen and (min-width: 701px) {
    .item-dialog {
        max-height: 90vh;
        //width: calc(100vw - 280px);
    }
}