.settings {
    .settings-sections {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .dashboard-content {
            display: flex;
            flex-direction: column;
        }
    }
}


/* Large Screens */
@media only screen and (min-width: 701px) {
    .settings {
        .settings-sections {
            .first-column {
                display: flex;
                flex-direction: column;
                align-items: stretch;
            }
        }
    }
}

/* Small Screens */
@media only screen and (max-width: 700px) {
    .settings {
        .settings-sections {
            .first-column {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
}
