.pawn-promotion-dialog {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal {
        border: 5px solid gray;
        z-index: 99;
        height: 10vmin;
        img {
            cursor: pointer;
            height: 100%;  
            width: auto;
            &:hover {
                background-color: var(--accent);
            }
        }
    }
}
