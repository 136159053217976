.recipe-view {
    .name {
        font-size: 18px;
        text-transform: uppercase;
        display: flex;
        margin: 10px 0 10px 0;
        padding-bottom: 10px;
        align-items: center;
        border-bottom: 1px solid var(--bs-gray-400);
    }
    .description {
        width: 100%;
        resize: none;
        color: var(--bs-body-color);
        border: 0;
        font-size: 16px;
        display: block;
        margin: 0;
        background: transparent;
    }
    .tag-list {
        display: flex;
        align-items: center;
    }
}

/* Large Screens */
@media only screen and (min-width: 951px) {
    .recipe-view {
        .description {
            height: calc(100vh - 200px);
        }
    }
}

/* Small Screens */
@media only screen and (max-width: 950px) {
    .recipe-view {
        .description {
            height: calc(100vh - 225px);
        }
    }
}