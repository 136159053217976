.custom-tooltip {
    background-color: var(--background);
    opacity: .9;
    padding: 10px;
    border: 1px solid var(--bs-gray-400);
    box-shadow: 2px 2px 10px 5px var(--shadow);
}

.chart-container {
    height: 275px;
    width: 275px;
}
