.notation {
    .notation-table-by-cell {
        tbody {
            tr {
                td {
                    &:hover {
                        background-color: rgba(0,0,0,.1);
                    }
                }
            }
        }
    }
    .notation-table-by-row {
        tbody {
            tr {
                &:hover {
                    background-color: rgba(0,0,0,.1);
                }
            }
        }
    }
}
