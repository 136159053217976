.score-dialog {
    background-color: var(--background);
    border-radius: 10px;
    box-shadow: 2px 2px 5px var(--shadow);
    opacity: .8;
    color: var(--bs-body-color);
    form {
        margin: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        table.score-input {
            tr {
                th {
                    padding: 5px;
                    text-align: right;
                }
                td {
                    padding: 5px;
                    text-align: left;
                }
            }
        }
    }
}
