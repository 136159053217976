.dashboard {
    display: flex;
    flex-wrap: wrap;
    ul {
        list-style-type: none;
        padding-left: 0;
        li {
            margin: 10px;
            font-size: 16px;
        }
    }
    .dashboard-section {
        h2 {
            text-align: center;
        }   
    }
    .chart {
        min-width: 400px;
    }
}

/* Large Screens */
@media only screen and (min-width: 701px) {
    .chart {
        min-width: 400px;
    }
}
