.stacked-bar-graph {
    .custom-tooltip {
        background-color: var(--background);
        opacity: .8;
        border: 1px solid var(--bs-gray-400);
        padding: 10px;
        TABLE {
            TR {
                TD, TH {
                    padding: 5px;
                    text-align: left;
                }
            }
        }
        .group-key {
            display: flex;
            align-items: center;
            .color-square {
                width: 13px;
                height: 13px;
                margin-right: 2px;
            }
        }
    }
}
